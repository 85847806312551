import gsap from "gsap";
import barba from '@barba/core';
import ScrollTrigger from "gsap/ScrollTrigger";

export default class{

	constructor(){
        gsap.registerPlugin(ScrollTrigger);
        this.addEvented = false
        barba.hooks.afterOnce((data) => {
            this.FlowFix()
        });
        barba.hooks.after((data) => {
            this.FlowFix()
        });
    }

    
    FlowFix(){
        /* 画面中央に要素を固定 */
        const top = document.querySelector('.index-flow');
        if (top) {

            ScrollTrigger.matchMedia({
                "(min-width: 992px)": () => {
                    ScrollTrigger.create({
                        trigger: ".index-flow__body",
                        start: 'top top',
                        end: 'bottom bottom',
                        pin: ".index-flow__side",
                        pinSpacing: false,
                        // markers: true
                    });

                    /* スクロール位置によって画像を変更する */
                    const triggers = document.querySelectorAll('[data-change-trigger]');
                    if(triggers.length > 0){
                        triggers.forEach((trigger, index) => {
                            gsap.to(`.image0${index + 1}`, {
                                opacity: 1,
                                scrollTrigger: {
                                    trigger: trigger,
                                    start: 'top center',
                                    // markers: true,
                                    toggleActions: 'play none none reverse',
                                    // invalidateOnRefresh: true,
                                },
                            });
                        });
                    }

                    /* Accordion開閉でコンテンツの高さが変わったときに、トリガー位置を連動させる */
                    // const accordions = document.querySelectorAll('.js-accordion__trigger');
                    // if (accordions.length > 0) {
                    //     accordions.forEach(accordion => {
                    //         accordion.addEventListener('click', function() {
                    //             // ScrollTrigger.refresh()
                    //             gsap.delayedCall(0.1, () => ScrollTrigger.refresh());
                    //         });
                    //     });
                    // }


                    // const image01 = document.querySelector('.image01');
                    // const image02 = document.querySelector('.image02');
                    // const image03 = document.querySelector('.image03');

                    // const firstTween = gsap.to(image01, {
                    //     opacity: 1,
                    //     scrollTrigger: {
                    //         invalidateOnRefresh: true,
                    //         trigger: ".item01",
                    //         start: "top center", 
                    //         markers: true,
                    //         toggleActions: 'play none none reverse', // リバースアニメーション
                    //     },
                    // });
                    // const secondTween = gsap.to(image02, {
                    //     opacity: 1,
                    //     scrollTrigger: {
                    //         trigger: ".item02",
                    //         start: "top center", 
                    //         markers: true,
                    //         toggleActions: 'play none none reverse', // リバースアニメーション
                    //     },
                    // });
                    // const thirdTween = gsap.to(image03, {
                    //     opacity: 1,
                    //     scrollTrigger: {
                    //         trigger: ".item03",
                    //         start: "top center", 
                    //         markers: true,
                    //         toggleActions: 'play none none reverse', // リバースアニメーション
                    //     },
                    // });

                    // firstTween.scrollTrigger.refresh()
                }
            });



            
        }

        

    }
}