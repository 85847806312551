
import barba from '@barba/core';
import anime from 'animejs';

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    init (){
       const heroCatch = document.querySelector("#hero--catch")
        if(heroCatch){
            let regexp = /[\u{3000}-\u{301C}\u{3041}-\u{3093}\u{309B}-\u{309E}]/mu;
            let heroPrimary = document.querySelectorAll('.hero-catch__row')
            if(heroPrimary.length > 0){
                heroPrimary.forEach( (h,i) => {
                    let pr_str = h.textContent.split("");
                    let str = "";
                    pr_str.map( s => {
                        str += "<span class='hero__str'>" + s  + "</span>";
                    })
                        
                    h.innerHTML = str;
                })
            }

            let heroSecondary = document.querySelectorAll('.hero--catch__secondary')
            if(heroSecondary){
                heroSecondary.forEach( (h,i) => {
                    let sc_str = h.textContent.split("");
                    let str = "";
                    sc_str.map( s => {
                        str += "<span class='hero__str'>" + s  + "</span>";
                    })
                        
                    h.innerHTML = str;
                })
            }

            
    
            setTimeout(function(){
                let str_animation =  anime.timeline();
                str_animation.add({
                    targets : '#hero--image-bg',
                    opacity:[1,0],
                    duration: 2500,
                    easing:"easeOutQuart",
                },800)
                str_animation.add({
                    targets: '#hero--catch svg path',
                    scale: [1.3,1],
                    opacity:[0,1],
                    duration: 900,
                    translateZ: 0,
                    easing:"easeOutExpo",
                    delay: (el,i)=>{
                        return i * 70
                    }
                },"-=1700")
                .add({
                    targets : '.hero--catch__button',
                    
                    scale: [1.3,1],
                    opacity:[0,1],
                    
                    easing: "easeOutExpo",
                    duration:1000
                },"-=700")
            },600)
       }
    }
}