import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.AboutAnm()
            this.KvAnm()
        })
        barba.hooks.after((data) => {
            this.AboutAnm()
            this.KvAnm()

        });
    }
    KvAnm() {
        const loop = document.querySelector("#hero--text-loop")
        if(loop){
            setTimeout(() => {
                loop.classList.add("active")
            }, 100);
        }  
    }
    AboutAnm (){
        const top = document.querySelector('.index-about');
        if (top) {
            
            const strs = document.querySelectorAll(".index-about__body");
            if (strs.length > 0) {
                const nodes = document.querySelectorAll('.js-span-wrap-text'); 

                nodes.forEach((node) => {
                    let spanWrapText = ""
                    node.textContent.split('').forEach((v) => {
                        spanWrapText +=  `<span class='str__animation'>${v}</span>`
                    });
                    
                    node.innerHTML = spanWrapText
                })

                
            }

            /* brは除く */
            // if (strs.length > 0) {
            //     strs.forEach((el) => {
            //         let pr_str = el.childNodes;
            //         let str = "";
            
            //         pr_str.forEach((node) => {
            //             if (node.nodeType === Node.TEXT_NODE) {
            //                 let textContent = node.textContent;
            //                 let splitContent = textContent.split("");
            //                 splitContent.forEach((s) => {
            //                     str += "<span class='str__animation'>" + s + "</span>";
            //                 });
            //             } else {
            //                 str += node.outerHTML;
            //             }
            //         });
            
            //         el.innerHTML = str;
            //     });
            // }


            // var t, n = (t = e)instanceof HTMLElement ? t : document.querySelector(t), i = r(n.childNodes), s = "";
            // i.forEach((function(e) {
            //     if (3 == e.nodeType) {
            //         var t = e.textContent.replace(/\r?\n/g, "");
            //         s += t.split("").reduce((function(e, t) {
            //             return e + "<span>".concat(t = " " != t ? t : "&nbsp", "</span>")
            //         }
            //         ), "")
            //     } else
            //         s += e.outerHTML
            // }
            // )),
            // n.innerHTML = s

            

            /* テキストアニメーション */
            const titles = document.querySelectorAll(".index-about__body")
            
            if(titles.length > 0){
                titles.forEach( title => {
                    const t = title.querySelectorAll('.index-about__body .str__animation')
                    gsap.to(t, {
                        opacity: 1,
                        ease: "power4",
                        stagger: 0.1,
                        scrollTrigger: {
                            trigger: title,
                            // markers: true,
                            scrub: .3,
                            start: 'top bottom',
                            end: '60% top'
                        }
                    }); 
                })
            }


            // if(strs.length > 0){
            //     strs.forEach( str => {
            //         const t = str.querySelector('.index-about__body .str__animation')
            //         gsap.to(t, {
            //             opacity: 1,
            //             stagger: 0.03,
            //             scrollTrigger: {
            //                 trigger: str,
            //                 // start: 'top bottom',
            //                 markers: true,
            //                 scrub: 0.3
            //             }
            //         }); 
            //     })
            // }
        }
    }
}