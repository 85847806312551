
import barba from '@barba/core';
import Swiper from 'swiper';

export default class{

	constructor(){

        barba.hooks.beforeOnce((data) => {
            this.heroSlider()
            this.featuresSlider()
            this.featuresSlider02()
            // this.featuresAutoSlider()
            this.blogSlider()

        })
        barba.hooks.after((data) => {
            this.heroSlider()
            this.featuresSlider()
            this.featuresSlider02()
            // this.featuresAutoSlider()
            this.blogSlider()
        });
        
    }
    
    heroSlider(){
        new Swiper ('#hero--slider',{
            loop: true,
            effect :'fade',
            speed:3000,
            allowTouchMove :false,
            simulateTouch:false,
            autoplay: {
                delay: 4000
            }
        })
    }

    featuresSlider() {
        new Swiper ('.js-features-slider',{
            loop: true,
            speed:500,
            pagination: {
                el: '.index-features-slider01 .swiper-pagination', // ページネーション要素のクラス
                clickable: true,
            },
            navigation: {
                nextEl: '.js-features-slider .swiper-button-next', // 「次へ」ボタン要素のクラス
                prevEl: '.js-features-slider .swiper-button-prev', // 「前へ」ボタン要素のクラス
            },
        })
    }

    featuresSlider02() {
        new Swiper ('.js-features-slider02',{
            loop: true,
            speed:500,
            pagination: {
                el: '.index-features-slider02 .swiper-pagination', // ページネーション要素のクラス
                clickable: true,
            },
            navigation: {
                nextEl: '.js-features-slider02 .swiper-button-next', // 「次へ」ボタン要素のクラス
                prevEl: '.js-features-slider02 .swiper-button-prev', // 「前へ」ボタン要素のクラス
            },
        })
    }

    // featuresAutoSlider() {
    //     new Swiper ('.js-features-autoslider',{
    //         loop: true,
    //         speed:500,
    //         allowTouchMove :false,
    //         simulateTouch:false,
    //         autoplay: {
    //             delay: 3000,
    //         },
    //     })
    // }
    
    blogSlider(){
        new Swiper('.js-blog-slider', {
            loop: false,
            speed: 600,
            easing:"linear",
            slidesPerView: '1.5',
            spaceBetween: 20,
            simulateTouch:true,
            breakpoints: {
                576: {
                    slidesPerView: '1.5',
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: '1.8',
                    spaceBetween: 30,
                },
                992: {
                    slidesPerView: '1.8',
                    spaceBetween: 50,
                }
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                draggable: true 
            }
        });
    }
}