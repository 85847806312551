
import barba from '@barba/core';
import MicroModal from 'micromodal';

export default class{

	constructor(){

        barba.hooks.afterOnce((data) => {
            this.init()
        });
        barba.hooks.leave((data) => {
            this.init.kill()
        });
        barba.hooks.afterEnter((data) => {
            this.init()
        });
    }
    init (){
        const attatch = (t) => {
            const id = t.getAttribute("data-modal-trigger")
            t.addEventListener('click',(e)=>{
                e.preventDefault()
                MicroModal.show(id,
                    {
                        openClass: 'is-open',
                        disableFocus: false,
                        disableScroll: true,
                        awaitOpenAnimation: true,
                        awaitCloseAnimation: true
                    }
                )
            })
        }
        const modalTrigger = document.querySelectorAll("[data-modal-trigger]")
        if(modalTrigger.length > 0){
            modalTrigger.forEach(t => {
                attatch(t)
            })
        }
    }
}